import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import EeTrialTopBanner from "../components/contactus/EeTrialTopBanner";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/contact-us.png",
};

const canonicalUrl = "https://hasura.io/contact-us/";

const ContactUsEETrial = props => (
  <Layout location={props.location}>
    <Seo
      title="Your Hasura Enterprise Edition trial"
      description="Congrats on starting your Hasura Enterprise Edition trial. For a quick glance at all the features included in Enterprise Edition"
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <EeTrialTopBanner location={props.location} />
  </Layout>
);

export default ContactUsEETrial;
