import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledUserBrandWrapper from "../shared/StyledUserBrandWrapper";
import UseHasuraFree from "../learn/UseHasuraFree";
import StyledVideoWrapper from "../shared/StyledVideoWrapper";
import { CheckCircle } from "../awsmicrosite/PlatformEnterprise";
import { scrollToTop } from "../common/Header/helper";
import { StyledContactUsWrapper, StyledAuthorWrapper} from "./topbanner"
import Paperform from "./Paperform";
import { StyledFormSuccessWrapper } from "./ContactUsForm";
import StyledFormWithHeader from "../shared/StyledFormWithHeader";
import { textCenter } from "../shared/CommonStyled";
import { COLORS } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

import {
  StyledTitle4,
  StyledDesc1,
  StyledSubTitle2,
  StyledDesc3,
  StyledDesc2,
  StyledBody1
} from "../shared/StyledTypography";
import { removePaddBottom } from "../shared/CommonStyled";
// import { mq } from "../../globals/utils";

// import phone from "./images/phone.svg";
import checkVerified from "./images/check-verified.svg";

const StyledUserBrandWrapperPadd = styled(StyledUserBrandWrapper)`
  padding-bottom: 48px;
`;

const StyledSectionWrapperGreyBg = styled(StyledSectionWrapper)`
  background-color: ${COLORS.grey_10};
`;

const StyledVideoWrapperPadd = styled(StyledVideoWrapper)`
  padding: 48px 0;
`;

const userBrandState = [
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519677/main-web/swiggy-black_mcgaix.svg",
    altText: "Swiggy",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519677/main-web/netlify-black_ltvjd8.svg",
    altText: "Netlify",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519677/main-web/bbva-black_ajnlzj.svg",
    altText: "BBVA",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519677/main-web/airbus-black_ojdufd.svg",
    altText: "Airbus",
  },
  {
    break: true,
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519679/main-web/walmart-black_aiiotg.svg",
    altText: "Walmart",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519192/main-web/Group_10627_rjvi7t.png",
    altText: "University of Virginia",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519677/main-web/atlassian-black_tsjvjl.svg",
    altText: "Atlassian",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519677/main-web/pipe-black_gd7t4w.svg",
    altText: "Pipe",
  },
];


export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowWidth, setWindowSize] = useState(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize(window.innerWidth);
    }
    // Add event listener
    // if (!!window.Intercom) {
    //   window.Intercom("update", { hide_default_launcher: true });
    // }
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowWidth;
}

const EeTrialTopBanner = ({ location }) => {
  const windowWidth = useWindowSize();
  const isMobile = windowWidth <= 767 ? true : false;
  const [isAliId, setIsAliId] = useState(false);
  const [isLocalContactUsEeTrial, setIsLocalContactUsEeTrial] = useState(false);
  const onSubmitCB = () => {
    if (typeof window !== undefined) {
      window.localStorage.setItem("contactUsEeTrialConsent", "true");
    }
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchAliId = searchParams.get("aliId");
    if (typeof window !== undefined) {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const contactUsEeTrialConsent = window.localStorage.getItem("contactUsEeTrialConsent");
        if (contactUsEeTrialConsent) {
          setIsLocalContactUsEeTrial(true);
        }
      }
    }
    if (searchAliId || searchAliId === "") {
      setIsAliId(true);
    }
  }, [location.search]);
  return (
    <>
      <StyledSectionWrapper css={removePaddBottom}>
        <StyledContainerWrapper>
          <StyledContactUsWrapper className="halfGrid">
            <div className="posSticky">
              <StyledTitle4 paddingBottom="pb8">Your Hasura Enterprise Edition trial</StyledTitle4>
              <StyledDesc1>
                Congrats on starting your Hasura Enterprise Edition trial. For a quick glance at all the features included in Enterprise Edition, check out this video:
              </StyledDesc1>
              <StyledVideoWrapperPadd>
                <div className="videoAspectRatio">
                  <iframe
                    loading="lazy"
                    title="Hasura Enterprise Edition"
                    src="https://www.youtube.com/embed/QE3mTzE9a3o"
                    frameBorder="0"
                    allowFullScreen
                  ></iframe>
                </div>
              </StyledVideoWrapperPadd>
              <StyledDesc1>
                And, please feel free to reach out to our team if:
                <ul>
                  <li><CheckCircle />You need to extend your trial</li>
                  <li><CheckCircle />Have questions about licensing</li>
                  <li><CheckCircle />Experienced issues with activation</li>
                </ul>
              </StyledDesc1>
            </div>
            <div>
              {isAliId && isLocalContactUsEeTrial ? (
                <StyledFormSuccessWrapper>
                  <div>
                    <div className="successIcon">
                      <img src={checkVerified} alt="Success" />
                    </div>
                    <StyledDesc2 css={textCenter}>Thank you for submitting a contact request regarding your 30-day Hasura Enterprise Edition Trial. Someone from the Hasura team will reach out to you soon.</StyledDesc2>
                  </div>
                </StyledFormSuccessWrapper>
              ) : (
                <StyledFormWithHeader>
                  <StyledDesc2 fontWeight="font_500" className="formHeader">
                    Fill out the form and a Hasura team member will reach out to you shortly
                  </StyledDesc2>
                  <Paperform
                    formId="sboe9fhu"
                    onSubmitCB={onSubmitCB}
                    styleClass="marketoFormWrapper webinarMarAuto contactUsWrapper"
                  />
                </StyledFormWithHeader>
              )}
            </div>
          </StyledContactUsWrapper>
        </StyledContainerWrapper>
      </StyledSectionWrapper>
      <StyledSectionWrapperGreyBg css={removePaddBottom}>
        <StyledContainerWrapper>
          <StyledContactUsWrapper className="gridGap">
            <div className="posSticky">
              <StyledBody1 fontWeight="font_600" paddingBottom="pb24">You are in good company</StyledBody1>
              <StyledUserBrandWrapperPadd>
                {userBrandState.map((brand, index) => (
                  <Fragment key={index}>
                    {brand.break ? (
                      <>{!isMobile ? <div className="breakFlex contactUsBreak"></div> : null}</>
                    ) : (
                      <div className="brandImg contactUsbrandImg">
                        <img src={brand.imgSrc} alt={brand.altText} />
                      </div>
                    )}
                  </Fragment>
                ))}
              </StyledUserBrandWrapperPadd>
              <StyledSubTitle2 fontWeight="font_300" variant="grey_80">
                “Hasure Cloud provided a faster and low-code way of accessing data while adhering to security best practices.”
              </StyledSubTitle2>
              <StyledAuthorWrapper className="removePaddBottom">
                <StyledDesc3 fontWeight="font_600">
                   Jagannath Vaikuntham
                  <br />
                  Lead Quality Engineer, Nutrien<br/>
                  <Link to="/case-studies/nutrien/" onClick={scrollToTop}>Read the case study</Link>
                </StyledDesc3>
              </StyledAuthorWrapper>
            </div>

          </StyledContactUsWrapper>
        </StyledContainerWrapper>
      </StyledSectionWrapperGreyBg>
      <StyledSectionWrapper css={removePaddBottom}>
        <StyledContainerWrapper>
          <UseHasuraFree />
        </StyledContainerWrapper>
      </StyledSectionWrapper>
    </>
  );
};

export default EeTrialTopBanner;
