import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";

import ContactUsForm from "./ContactUsForm";
import StyledSectionWrapper from "../shared/StyledSectionWrapper";
import StyledContainerWrapper from "../shared/StyledContainerWrapper";
import StyledUserBrandWrapper from "../shared/StyledUserBrandWrapper";
import UseHasuraFree from "../learn/UseHasuraFree";

import {
  StyledTitle4,
  StyledDesc1,
  StyledSubTitle2,
  StyledDesc3,
} from "../shared/StyledTypography";
import { removePaddBottom } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import peterdowns from "./images/peterdowns.png";
// import swiggy from "../imagescommon/swiggy-black.svg";
// import netlify from "../imagescommon/netlify-black.svg";
// import airbus from "../imagescommon/airbus-black.svg";
// import walmart from "../imagescommon/walmart-black.svg";
// import virginia from "../imagescommon/virginia-black.svg";
// import atlassian from "../imagescommon/atlassian-black.svg";

const userBrandState = [
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519677/main-web/swiggy-black_mcgaix.svg",
    altText: "Swiggy",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519677/main-web/netlify-black_ltvjd8.svg",
    altText: "Netlify",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519677/main-web/bbva-black_ajnlzj.svg",
    altText: "BBVA",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519677/main-web/airbus-black_ojdufd.svg",
    altText: "Airbus",
  },
  {
    break: true,
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519679/main-web/walmart-black_aiiotg.svg",
    altText: "Walmart",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519192/main-web/Group_10627_rjvi7t.png",
    altText: "University of Virginia",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519677/main-web/atlassian-black_tsjvjl.svg",
    altText: "Atlassian",
  },
  {
    imgSrc:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1668519677/main-web/pipe-black_gd7t4w.svg",
    altText: "Pipe",
  },
];

export const StyledContactUsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 450px;
  grid-gap: 130px;
  padding-bottom: 100px;
  &.halfGrid {
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
  }
  &.gridGap {
    grid-gap: 50px;
    grid-template-columns: 1fr;
  }
  .posSticky {
    position: sticky;
    top: 100px;
    align-self: flex-start;
  }
  .showMobileView {
    display: none;
  }
  .pt40 {
    padding-top: 40px;
  }
  ul {
    li {
      svg {
        margin-right: 12px;
        margin-top: 2px;
      }
    }
  }
  ${mq.between("md", "lg")} {
    grid-template-columns: 1fr 375px;
    grid-gap: 60px;
    padding-bottom: 60px;
  }
  ${mq.below.md} {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    padding-bottom: 50px;
    &.halfGrid {
      grid-template-columns: 1fr;
      grid-gap: 50px;
    }
    &.gridGap {
      grid-gap: 50px;
    }
    .posSticky {
      position: static;
    }
    .showMobileView {
      display: block;
    }
    .hideMobileView {
      display: none;
    }
  }
`;
export const StyledAuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 70px 0;
  padding-top: 16px;
  &.removePaddBottom {
    padding-bottom: 0;
  }
  .authorImg {
    padding-right: 16px;
    img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
    }
  }
  ${mq.between("md", "lg")} {
    padding-bottom: 30px;
  }
  ${mq.below.md} {
    padding-bottom: 30px;
  }
`;

export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowWidth, setWindowSize] = useState(undefined);

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize(window.innerWidth);
    }
    // Add event listener
    // if (!!window.Intercom) {
    //   window.Intercom("update", { hide_default_launcher: true });
    // }
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowWidth;
}

const TopBanner = ({ location }) => {
  const windowWidth = useWindowSize();
  const isMobile = windowWidth <= 767 ? true : false;

  return (
    <StyledSectionWrapper css={removePaddBottom}>
      <StyledContainerWrapper>
        <StyledContactUsWrapper>
          <div className="posSticky">
            <StyledTitle4 paddingBottom="pb8">Reach out to our team</StyledTitle4>
            <StyledDesc1 paddingBottom="pb48">
              We’re always looking to understand how we can help our customers make the best use of
              Hasura. Our sales team would love to set up a call/chat.{" "}
            </StyledDesc1>
            <div className="hideMobileView">
              <StyledSubTitle2 fontWeight="font_300" variant="grey_80">
                “Hasura is a fantastic way to create a data fetching layer to our database. It’s
                ultra-stable and often better at planning queries than ones we were writing
                ourselves.”
              </StyledSubTitle2>
              <StyledAuthorWrapper>
                <div className="authorImg">
                  <img src={peterdowns} alt="Peter Downs" loading="lazy" />
                </div>
                <StyledDesc3 fontWeight="font_600">
                  Peter Downs
                  <br />
                  Director of Engineering, Pipe
                </StyledDesc3>
              </StyledAuthorWrapper>
              <StyledUserBrandWrapper>
                {userBrandState.map((brand, index) => (
                  <Fragment key={index}>
                    {brand.break ? (
                      <>{!isMobile ? <div className="breakFlex contactUsBreak"></div> : null}</>
                    ) : (
                      <div className="brandImg contactUsbrandImg">
                        <img src={brand.imgSrc} alt={brand.altText} />
                      </div>
                    )}
                  </Fragment>
                ))}
              </StyledUserBrandWrapper>
            </div>
          </div>
          <ContactUsForm location={location} />
          <div className="showMobileView">
            <StyledSubTitle2 className="pt40" fontWeight="font_300" variant="grey_80">
              “Hasura is a fantastic way to create a data fetching layer to our database. It’s
              ultra-stable and often better at planning queries than ones we were writing
              ourselves.”
            </StyledSubTitle2>
            <StyledAuthorWrapper>
              <div className="authorImg">
                <img src={peterdowns} alt="Peter Downs" />
              </div>
              <StyledDesc3 fontWeight="font_600">
                Peter Downs
                <br />
                Director of Engineering, Pipe
              </StyledDesc3>
            </StyledAuthorWrapper>
            <StyledUserBrandWrapper>
              {userBrandState.map((brand, index) => (
                <Fragment key={index}>
                  {brand.break ? (
                    <>{!isMobile ? <div className="breakFlex contactUsBreak"></div> : null}</>
                  ) : (
                    <div className="brandImg contactUsbrandImg">
                      <img src={brand.imgSrc} alt={brand.altText} />
                    </div>
                  )}
                </Fragment>
              ))}
            </StyledUserBrandWrapper>
          </div>
        </StyledContactUsWrapper>
        <UseHasuraFree />
      </StyledContainerWrapper>
    </StyledSectionWrapper>
  );
};

export default TopBanner;
