import styled from "styled-components";

// import { COLORS, ELEVATION } from "../../globals/designSystem";
import { mq } from "../../globals/utils";

export const StyledFormWithHeader = styled.div`
  background: #fff;
  box-shadow: 0px 14px 60px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  align-self: flex-start;
  .marketoFormWrapper {
    box-shadow: none !important;
    padding-top: 0px !important;
  }
  .cursorPointer {
    cursor: pointer;
  }
  .brand {
    text-align: center;
    padding-bottom: 10px;
    img {
      display: inline-block;
    }
  }
  .formHeader {
    padding: 40px 40px 0 40px;
    .headerIcon {
      margin-right: 12px;
      margin-top: -3px;
    }
    .pt32 {
      padding-top: 32px;
    }
  }

  .formFooter {
    padding: 0 40px 40px 40px;
    margin-top: -20px;
    z-index: 1;
    position: relative;
  }
  .p0 {
    padding: 0;
  }
  .mobileShow {
    display: none;
  }
  ${mq.between("md", "lg")} {
    .formHeader {
      padding: 32px 32px 0 32px;
    }
    .formFooter {
      padding: 0 32px 32px 32px;
    }
    .p0 {
      padding: 0;
    }
  }
  ${mq.below.md} {
    padding-bottom: 50px;
    .mobileShow {
      display: flex;
    }
    .marketoFormWrapper {
      padding-top: 24px !important;
      &.webinarMarAuto {
        margin-left: unset;
        max-width: none;
      }
      &.mktoFormPaddTop {
        padding-top: 0 !important;
      }
      &.mktoFormMarTop {
        margin-top: 0 !important;
      }
    }
    .formHeader {
      padding: 24px 24px 0 24px;
    }
    .formFooter {
      padding: 0 24px 24px 24px;
    }
    .p0 {
      padding: 0;
    }
  }
`;

export default StyledFormWithHeader;
