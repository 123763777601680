import React, { useState, useEffect } from "react";
import styled from "styled-components";

import StyledFormWithHeader from "../shared/StyledFormWithHeader";
import { StyledDesc1, StyledDesc2 } from "../shared/StyledTypography";
import { COLORS, ELEVATION } from "../../globals/designSystem";
import { textCenter } from "../shared/CommonStyled";
import { mq } from "../../globals/utils";

import phone from "./images/phone.svg";
import checkVerified from "./images/check-verified.svg";
import Paperform from "./Paperform";

export const StyledFormSuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.white};
  box-shadow: ${ELEVATION.l_3};
  border-radius: 8px;
  .successIcon {
    padding-bottom: 16px;
    text-align: center;
    img {
      display: inline-block;
    }
  }
  ${mq.below.md} {
    padding: 40px 24px;
  }
`;

const ContactUsForm = props => {
  const [showQueryType, setShowQueryType] = useState(false);
  const [isAliId, setIsAliId] = useState(false);
  const [isLocalContactUs, setIsLocalContactUs] = useState(false);
  const onSubmitCB = () => {
    if (typeof window !== undefined) {
      window.localStorage.setItem("contactUsConsent", "true");
    }
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    const searchType = searchParams.get("type");
    const searchAliId = searchParams.get("aliId");
    if (searchType) {
      setShowQueryType(true);
    } else {
      setShowQueryType(false);
    }
    if (typeof window !== undefined) {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const contactUsConsent = window.localStorage.getItem("contactUsConsent");
        if (contactUsConsent) {
          setIsLocalContactUs(true);
        }
      }
    }
    if (searchAliId || searchAliId === "") {
      setIsAliId(true);
    }
  }, [props.location.search]);
  return (
    <>
      {isAliId && isLocalContactUs ? (
        <StyledFormSuccessWrapper>
          <div>
            <div className="successIcon">
              <img src={checkVerified} alt="Success" />
            </div>
            <StyledDesc1 fontWeight="font_600" paddingBottom="pb8" css={textCenter}>Thank you for your interest in Hasura.</StyledDesc1>
            <StyledDesc2 css={textCenter}>We will be in touch shortly!</StyledDesc2>
          </div>
        </StyledFormSuccessWrapper>
      ) : (
        <StyledFormWithHeader>
          <StyledDesc2 fontWeight="font_500" className="formHeader">
            <img src={phone} alt="Registration" className="headerIcon" />
            CONTACT US
          </StyledDesc2>
          <Paperform
            formId={showQueryType ? "hf-1001" : "hf-1051"}
            onSubmitCB={onSubmitCB}
            styleClass="marketoFormWrapper webinarMarAuto contactUsWrapper"
          />
        </StyledFormWithHeader>
      )}
    </>
  );
};

export default ContactUsForm;
